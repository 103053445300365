import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Moment from "react-moment";
import moment from "moment/min/moment-with-locales";
import "moment/locale/pl";
import * as serviceWorker from "./serviceWorker";
import { subscribeUser } from "./subscription";

if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
}

Moment.startPooledTimer(60000);

Moment.globalMoment = moment;
Moment.globalLocale = "pl";

ReactDOM.render(<App />, document.getElementById("root"));
subscribeUser();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
