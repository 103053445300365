import { combineReducers } from "redux";
import eventReducer from "./eventReducer";
import errorReducer from "./errorReducer";
import authReducer from "./authReducer";
import filterReducer from "./filterReducer";

export default combineReducers({
  event: eventReducer,
  error: errorReducer,
  auth: authReducer,
  filter: filterReducer
});
