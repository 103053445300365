import React, { Component, Fragment } from "react";
import { NavLink, withRouter } from "react-router-dom";

import EventListDate from "../../EventListDate";

class EventDateCell extends Component {
  render() {
    let event = this.props.event;
    return (
      <NavLink
        to={{ pathname: `/event/${event._id}`, event }}
        activeClassName="active"
      >
        <EventListDate date={event.created_at} />
      </NavLink>
    );
  }
}

export default withRouter(EventDateCell);
