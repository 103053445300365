import React, { Component, Fragment } from "react";
import { NavLink, withRouter } from "react-router-dom";

class EventSenderCell extends Component {
  render() {
    let event = this.props.event;
    if (
      !event ||
      !event.context ||
      !(event.context.customer || event.context.landlord)
    ) {
      return <Fragment></Fragment>;
    }
    let senderData = [];
    if (
      event.context.customer &&
      event.context.customer.first_name &&
      event.context.customer.last_name
    ) {
      senderData.push(
        event.context.customer.first_name +
          " " +
          event.context.customer.last_name
      );
    }
    if (event.context.landlord) {
      senderData.push(
        event.context.landlord.first_name +
          " " +
          event.context.landlord.last_name
      );
    }
    const senderLabel = senderData.join(", ");
    return (
      <NavLink to={{ pathname: `/event/${event._id}`, event }}>
        {senderLabel}
      </NavLink>
    );
  }
}

export default withRouter(EventSenderCell);
