import {
  FILTER_TEXT_SET,
  FILTER_TEXT_CLEAR,
  FILTER_APPLY,
  FILTER_CATEGORY_CLEAR,
  FILTER_CATEGORY_SET,
  FILTER_ALL_CLEAR
} from "./types";
import store from "./../store";
import { getEvents } from "./eventsActions";

export const setFilterText = filterText => {
  return {
    type: FILTER_TEXT_SET,
    payload: filterText
  };
};

export const setFilterCategory = filterCategory => {
  return {
    type: FILTER_CATEGORY_SET,
    payload: filterCategory
  };
};

export const applyFilter = () => dispatch => {
  dispatch(getEvents());
  dispatch({
    type: FILTER_APPLY
  });
};

export const clearFilterText = () => dispatch => {
  dispatch({
    type: FILTER_TEXT_CLEAR
  });
  dispatch(getEvents());
};

export const clearFilterCategory = () => dispatch => {
  dispatch({
    type: FILTER_CATEGORY_CLEAR
  });
  dispatch(getEvents());
};
export const clearFilterAll = () => dispatch => {
  dispatch({
    type: FILTER_ALL_CLEAR
  });
  dispatch(getEvents());
};
