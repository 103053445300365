import React, { Component } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";

class EventFilterSet extends Component {
  render() {
    return (
      <Nav vertical>
        <NavItem active>
          <NavLink active href="#">
            Odebrane
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="#">Wysłane</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="#">Wszystkie</NavLink>
        </NavItem>
      </Nav>
    );
  }
}

export default EventFilterSet;
