import React, { Component } from "react";
import moment from "moment";
import Moment from "react-moment";

class EventListDate extends Component {
  render() {
    let momentNow = new moment();
    let momentMidnightToday = new moment()
      .hours(0)
      .minutes(0)
      .seconds(0);
    let momentDate = new moment(this.props.date);
    let momentDiff = Math.ceil(momentNow.diff(momentDate) / 1000);
    if (momentDate > momentMidnightToday) {
      return (
        <span>
          dzisiaj, <Moment format="HH:mm">{this.props.date}</Moment>
          <small className="d-none d-md-inline">
            &nbsp;(
            <Moment interval="60000" fromNow withTitle>
              {this.props.date}
            </Moment>
            )
          </small>
        </span>
      );
    }
    if (momentDiff <= 60 * 60 * 48) {
      return (
        <span>
          wczoraj, <Moment format="HH:mm">{this.props.date}</Moment>
        </span>
      );
    }
    return (
      <span>
        <Moment format="ddd., DD.MM.YYYY, HH:mm">{this.props.date}</Moment>
      </span>
    );
  }
}

export default EventListDate;
