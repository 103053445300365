import {
  FILTER_TEXT_SET,
  FILTER_CATEGORY_SET,
  FILTER_TEXT_CLEAR,
  FILTER_APPLY,
  FILTER_CATEGORY_CLEAR,
  FILTER_ALL_CLEAR
} from "../actions/types";

const initialState = {
  filterText: null,
  filterCategory: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FILTER_TEXT_SET:
      return {
        ...state,
        filterText: action.payload
      };
    case FILTER_CATEGORY_SET:
      return {
        ...state,
        filterCategory: action.payload
      };
    case FILTER_TEXT_CLEAR:
      return {
        ...state,
        filterText: null
      };
    case FILTER_CATEGORY_CLEAR:
      return {
        ...state,
        filterCategory: null
      };
    case FILTER_ALL_CLEAR:
      return {
        ...state,
        filterCategory: null,
        filterText: null
      };
    case FILTER_APPLY:
    default:
      return state;
  }
}
