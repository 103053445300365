import React, { Component, Fragment } from "react";
import { Badge } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { CATEGORIES_SETTINGS } from "../../categories";

class FilterCategoryBadge extends Component {
  render() {
    const label = this.props.label
      ? this.props.label
      : CATEGORIES_SETTINGS[this.props.filterCategory]?.label;
    const color = this.props.color
      ? this.props.color
      : CATEGORIES_SETTINGS[this.props.filterCategory]?.color;
    const closingX = this.props.hasClosingX ? (
      <FontAwesomeIcon className="ml-1" icon={faTimes} />
    ) : (
      <Fragment></Fragment>
    );
    return (
      <Badge
        className="pointerable"
        color={color}
        onClick={this.props.onClickHandler}
      >
        {label}
        {closingX}
      </Badge>
    );
  }
}

export default FilterCategoryBadge;
