import {
  GET_EVENTS,
  EVENTS_LOADING,
  EVENT_UPDATING,
  EVENT_SET_CURRENT
} from "../actions/types";
import { STATES } from "mongoose";

const initialState = {
  events: [],
  currentEvent: null,
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_EVENTS:
      return {
        ...state,
        events: action.payload,
        loading: false
      };
    case EVENTS_LOADING:
      return {
        ...state,
        loading: true
      };
    case EVENT_SET_CURRENT:
      return {
        ...state,
        currentEvent: action.payload
      };
    default:
      return state;
  }
}
