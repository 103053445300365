import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import { Container, Toast, ToastHeader, ToastBody } from "reactstrap";
import AppNavbar from "./components/AppNavbar";

import { Provider } from "react-redux";
import store from "./store";
import { loadUser } from "./actions/authActions";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import EventDashboard from "./components/EventDashboard";

window.React = React;

class App extends Component {
  componentDidMount() {
    store.dispatch(loadUser());
  }

  render() {
    return (
      <BrowserRouter>
        <Provider store={store}>
          <Container>
            <div className="App">
              <AppNavbar />
              <EventDashboard />
            </div>
          </Container>
        </Provider>
      </BrowserRouter>
    );
  }
}

export default App;
