import React, { Component } from "react";

import { NavLink, withRouter } from "react-router-dom";

import { CATEGORIES_SETTINGS } from "../../categories";
import EventTitleCell from "./EventListTableCells/EventTitleCell";
import EventSenderCell from "./EventListTableCells/EventSenderCell";
import EventDateCell from "./EventListTableCells/EventDateCell";

import MediaQuery from "react-responsive";

class EventListTableRow extends Component {
  render() {
    let event = this.props.event;
    return (
      <tr
        key={this.props.key}
        className={`event-label ${
          CATEGORIES_SETTINGS[event.event_category]?.is_row_coloured
            ? CATEGORIES_SETTINGS[event.event_category]?.color
            : ""
        } ${
          this.props.event.currentEvent &&
          event._id === this.props.event.currentEvent._id
            ? "active"
            : ""
        }`}
      >
        <MediaQuery maxDeviceWidth={1224}>
          <td>
            <div className="row">
              <div className="col text-left">
                <EventTitleCell
                  event={event}
                  setFilterCategory={this.setFilterCategory}
                />
              </div>
            </div>
            <div className="row">
              <div className="col text-left">
                <EventSenderCell event={event} />
              </div>
              <div className="col text-right date-cell">
                <EventDateCell event={event} />
              </div>
            </div>
          </td>
        </MediaQuery>
        <MediaQuery minDeviceWidth={1225}>
          <td>
            <EventTitleCell
              event={event}
              setFilterCategory={this.setFilterCategory}
            />
          </td>
          <td>
            <EventSenderCell event={event} />
          </td>

          <td className="date-cell">
            <EventDateCell event={event} />
          </td>
        </MediaQuery>
      </tr>
    );
  }
}

export default withRouter(EventListTableRow);
