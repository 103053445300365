import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Button,
  UncontrolledCollapse,
  Badge,
} from "reactstrap";
import MediaQuery from "react-responsive";
import { connect } from "react-redux";
import { getEvents } from "../actions/eventsActions";
import PropTypes from "prop-types";

import EventDetails from "./BottomContent/EventDetails";
import EventListTable from "./BottomContent/EventListTable";
import EventFilterSet from "./BottomContent/EventFilterSet";

import socketIOClient from "socket.io-client";
import axios from "axios";
import { Route, withRouter, Switch } from "react-router-dom";
import { CATEGORIES_SETTINGS } from "../categories";

class EventDashboard extends Component {
  state = {
    currentEvent: null,
    response: null,
    audioNotification: null,
    events: null,
    filterText: null,
    currentPage: 1,
    eventsPerPage: 100,
  };

  static propTypes = {
    getEvents: PropTypes.func.isRequired,
    event: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool,
  };

  componentDidMount() {
    this.props.getEvents();
    const socket = socketIOClient(process.env.REACT_APP_SOCKET_ENDPOINT);

    socket.on("newEvent", (data) => {
      this.props.getEvents();
      if (
        data &&
        data.event_category &&
        CATEGORIES_SETTINGS[data.event_category]
      ) {
        const audioFilename = CATEGORIES_SETTINGS[data.event_category]?.sound;
        console.log("audioFilename", "/" + audioFilename);
        let audioElement = new Audio("/" + audioFilename);
        audioElement.volume = 0.3;
        this.setState({
          audioNotification: audioElement,
        });
        this.state.audioNotification.play();
      }

      console.log("newEvent data: ", data);
      if (window && localStorage) {
        const subscription = JSON.parse(
          localStorage.getItem("push_subscription")
        );

        const payload = JSON.stringify({
          title: data.title,
          body: data.description,
          requireInteraction: true,
          icon: "/logo192.png",
        });
        axios
          .post("/notifications/send", { subscription, payload })
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.error(err);
          });
      }
    });
  }

  setCurrentEvent = (event) => {
    this.setState({ currentEvent: event });
  };

  render() {
    const { events } = this.props.event;
    if (!this.props.isAuthenticated) {
      return <Container></Container>;
    }
    return (
      <Container className="eventDashboardContainer" fluid>
        <MediaQuery maxDeviceWidth={1224}>
          <div className="eventListContainer" style={{ height: "100%" }}>
            <EventListTable />
          </div>
          <div className="eventDashboardModal">
            <div className="eventContentContainer">
              <Switch>
                <Route
                  path="/event/:id"
                  render={(props) => (
                    <EventDetails {...props} key={props.match.params.id} />
                  )}
                />
              </Switch>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery minDeviceWidth={1225}>
          <div className="eventDashboardTopRow">
            <div className="eventFilterSetContainer d-none d-md-block">
              <EventFilterSet />
            </div>
            <div className="ml-12 eventListContainer">
              <EventListTable />
            </div>
          </div>
          <div className="eventDashboardBottomRow">
            <div className="eventContentContainer">
              <Switch>
                <Route
                  path="/event/:id"
                  render={(props) => (
                    <EventDetails {...props} key={props.match.params.id} />
                  )}
                />
              </Switch>
            </div>
          </div>
        </MediaQuery>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  event: state.event,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { getEvents })(EventDashboard);
