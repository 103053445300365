import React, { Component, Fragment } from "react";
import {
  Container,
  Card,
  CardHeader,
  CardBody,
  Button,
  ButtonGroup,
  ModalHeader,
  Modal,
  ModalBody,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faReply,
  faReplyAll,
  faTrashAlt,
  faArrowLeft,
  faExternalLinkSquareAlt,
  faPhoneAlt,
  faEnvelopeSquare,
} from "@fortawesome/free-solid-svg-icons";
import MediaQuery from "react-responsive";
import { useHistory, useParams, withRouter, Router } from "react-router-dom";
import { connect } from "react-redux";
import {
  getEvents,
  updateEventIsRead,
  setCurrentEvent,
} from "../../actions/eventsActions";
import PropTypes from "prop-types";

class EventDetails extends Component {
  state = {
    event: null,
    events: null,
    detailsModalOpen: true,
  };

  static propTypes = {
    getEvents: PropTypes.func.isRequired,
    updateEventIsRead: PropTypes.func.isRequired,
    setCurrentEvent: PropTypes.func.isRequired,
    event: PropTypes.object.isRequired,
  };

  setEventsInState(events) {
    this.setState({ events });
  }

  componentDidMount() {
    // this.props.getEvents();
    // console.log("this.props.event:", this.props.event);

    if (this.props.match.params.id && this.props.event.events) {
      const event = this.findEventById(this.props.match.params.id);
      if (event) {
        console.log("[update] State event set to ", event);
        this.setState(event);
        this.props.setCurrentEvent(event);
        this.props.updateEventIsRead(event._id, true);
        this.setState({ detailsModalOpen: true });
      }
    }
  }

  markEventAsUnread = (id) => {
    this.props.updateEventIsRead(id, false);
  };

  findEventById(id) {
    for (let i = 0; i < this.props.event.events.length; i++) {
      let event = this.props.event.events[i];
      if (event._id === id) {
        return event;
      }
    }
    return null;
  }

  renderQueryContextButtons(context) {
    if (!context || !context.query) {
      return <Fragment></Fragment>;
    }
    const query = context.query;
    return (
      <ButtonGroup className="mr-3">
        <Button
          size="sm"
          outline
          href={`https://www.sonar.travel/backend.php/query/${query.id}/edit`}
          target="_blank"
        >
          karta rezerwacji&nbsp;
          <FontAwesomeIcon icon={faExternalLinkSquareAlt} />
        </Button>
      </ButtonGroup>
    );
  }

  renderCustomerContextButtons(context) {
    if (!context || !context.customer) {
      return <Fragment></Fragment>;
    }
    const customer = context.customer;
    return (
      <ButtonGroup className="mr-3">
        <Button size="sm" outline href={`tel:${customer.mobile_no}`}>
          Zadzwoń do klienta&nbsp;
          <FontAwesomeIcon icon={faPhoneAlt} />
        </Button>
        <Button
          size="sm"
          outline
          href={`tel:+48618800001,1,${
            customer.mobile_no ? customer.mobile_no.replace("+", "") : ""
          }#`}
        >
          Wdzwoń&nbsp;
          <FontAwesomeIcon icon={faPhoneAlt} />
        </Button>
        <Button size="sm" outline href={`mailto:${customer.email}`}>
          Wyślij email do klienta&nbsp;
          <FontAwesomeIcon icon={faEnvelopeSquare} />
        </Button>
      </ButtonGroup>
    );
  }
  renderVillaContextButtons(context) {
    if (!context || !context.villa) {
      return <Fragment></Fragment>;
    }
    const villa = context.villa;
    return <ButtonGroup></ButtonGroup>;
  }
  renderLandlordContextButtons(context) {
    if (!context || !context.landlord) {
      return <Fragment></Fragment>;
    }
    const landlord = context.landlord;
    return (
      <ButtonGroup className="float-right">
        <Button
          size="sm"
          outline
          color="info"
          href={`tel:${landlord.mobile_no}`}
        >
          Zadzwoń do landlorda&nbsp;
          <FontAwesomeIcon icon={faPhoneAlt} />
        </Button>
        <Button
          size="sm"
          outline
          color="info"
          href={`mailto:${landlord.email}`}
        >
          Wyślij email do landlorda&nbsp;
          <FontAwesomeIcon icon={faEnvelopeSquare} />
        </Button>
      </ButtonGroup>
    );
  }
  renderDefaultButtons = (event) => {
    return (
      <ButtonGroup className="mr-3">
        <Button
          size="sm"
          outline
          color="primary"
          onClick={() => {
            this.markEventAsUnread(event._id);
          }}
        >
          Oznacz jako nieprzeczytane&nbsp;
          <FontAwesomeIcon icon={faEnvelopeSquare} />
        </Button>
      </ButtonGroup>
    );
  };
  toggleModal = () => {
    this.setState({ detailsModalOpen: !this.state.detailsModalOpen });
  };

  render() {
    console.log("EventDetails render() called");
    const event =
      this.state.event || this.findEventById(this.props.match.params.id);
    console.log("Event in render() is: ", event);
    if (!event) {
      console.log("No event");
      return <Fragment>[-]</Fragment>;
    }
    console.log(event.context);
    return (
      <Fragment>
        <MediaQuery maxDeviceWidth={1224}>
          <div className="">
            <Modal
              isOpen={this.state.detailsModalOpen}
              toggle={this.toggleModal}
              onClosed={() => {
                this.props.history.push("/");
              }}
            >
              <ModalHeader toggle={this.toggleModal}>{event.title}</ModalHeader>
              <ModalBody>
                <div
                  className="event-content-inner"
                  dangerouslySetInnerHTML={{ __html: event.content }}
                ></div>
              </ModalBody>
            </Modal>
          </div>
        </MediaQuery>
        <MediaQuery minDeviceWidth={1225}>
          <div className="event-content">
            <Card>
              <CardHeader>
                <h6>{event.title}</h6>
                {this.renderQueryContextButtons(event.context)}
                {this.renderDefaultButtons(event)}
                {this.renderCustomerContextButtons(event.context)}
                {this.renderVillaContextButtons(event.context)}
                {this.renderLandlordContextButtons(event.context)}
              </CardHeader>
              <CardBody>
                <div
                  className="event-content-inner"
                  dangerouslySetInnerHTML={{ __html: event.content }}
                ></div>
              </CardBody>
            </Card>
          </div>
        </MediaQuery>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  event: state.event,
});

export default connect(mapStateToProps, {
  getEvents,
  updateEventIsRead,
  setCurrentEvent,
})(EventDetails);
