export const CATEGORIES_SETTINGS = {
  "Query::Acceptance": {
    label: "Akceptacja rezerwacji",
    color: "warning",
    sound: "sonar.mp3",
    is_row_coloured: false,
  },
  "Query::ExpiredAcceptance": {
    label: "Przeterminowana rezerwacja",
    color: "info",
    sound: "sonar.mp3",
    is_row_coloured: false,
  },
  "Query::ExpiringAcceptance": {
    label: "Upływająca rezerwacja",
    color: "info",
    sound: "sonar.mp3",
    is_row_coloured: false,
  },
  "Query::Confirmation": {
    label: "Potwierdzona rezerwacja",
    color: "event-red",
    sound: "sonar_sonar.mp3",
    is_row_coloured: true,
  },
  "Query::Deconfirmation": {
    label: "Odpotwierdzona rezerwacja",
    color: "secondary",
    sound: "sonar.mp3",
    is_row_coloured: false,
  },
  "Query::Cancellation": {
    label: "Odwołana rezerwacja",
    color: "secondary",
    sound: "sonar.mp3",
    is_row_coloured: false,
  },
  "Query::Rejection": {
    label: "Odrzucona rezerwacja",
    color: "primary",
    sound: "sonar.mp3",
    is_row_coloured: false,
  },
  "Query::Request": {
    label: "Zapytanie o termin",
    color: "secondary",
    sound: null,
    is_row_coloured: false,
  },
  "Query::CustomerRequest": {
    label: "Zapytanie klienta",
    color: "secondary",
    sound: "pluck.mp3",
    is_row_coloured: false,
  },
  "Query::Creation": {
    label: "Podjęto proces rezerwacji",
    color: "event-blue",
    sound: "jubilation.mp3",
    is_row_coloured: true,
  },
  "Query::LeftForPayment": {
    label: "Przejście do płatności",
    color: "default",
    sound: "all_eyes_on_me.mp3",
    is_row_coloured: false,
  },
  "Villa::CalendarsUpdate": {
    label: "Zmiana na kalendarzach",
    color: "warning",
    sound: "sonar.mp3",
    is_row_coloured: false,
  },
  "Customer::IncomingCall": {
    label: "Nowa rozmowa telefoniczna",
    color: "event-green",
    sound: "sonar.mp3",
    is_row_coloured: true,
  },
  "Customer::CallEnded": {
    label: "Raport z rozmowy tel.",
    color: "event-green",
    sound: null,
    is_row_coloured: true,
  },
  "Customer::AnsweredCall": {
    label: "Odebrana rozmowa tel.",
    color: "default",
    sound: null,
    is_row_coloured: false,
  },
  "Customer::IncomingTextMessage": {
    label: "SMS od klienta",
    color: "default",
    sound: "long_chime_sound.mp3",
    is_row_coloured: false,
  },
  "Landlord::IncomingTextMessage": {
    label: "SMS od gospodarza",
    color: "default",
    sound: "long_chime_sound.mp3",
    is_row_coloured: false,
  },
  "Query::ChangeRequest": {
    label: "Prośba o zmianę",
    color: "secondary",
    sound: "sonar.mp3",
    is_row_coloured: false,
  },
  "Query::CancellationRequest": {
    label: "Prośba o odwołanie",
    color: "secondary",
    sound: "sonar.mp3",
    is_row_coloured: false,
  },
};
