export const GET_EVENTS = "GET_EVENTS";
export const EVENTS_LOADING = "EVENTS_LOADING";
export const EVENT_UPDATING = "EVENT_UPDATING";
export const EVENT_UPDATED = "EVENT_UPDATED";
export const EVENT_SET_CURRENT = "EVENT_SET_CURRENT";

export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const FILTER_TEXT_SET = "FILTER_TEXT_SET";
export const FILTER_CATEGORY_SET = "FILTER_CATEGORY_SET";
export const FILTER_CATEGORY_CLEAR = "FILTER_CATEGORY_CLEAR";
export const FILTER_TEXT_CLEAR = "FILTER_TEXT_CLEAR";
export const FILTER_ALL_CLEAR = "FILTER_ALL_CLEAR";
export const FILTER_APPLY = "FILTER_APPLY";
