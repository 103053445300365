import React, { Component, Fragment } from "react";
import {
  Container,
  ListGroup,
  ListGroupItem,
  Button,
  UncontrolledCollapse,
  Badge
} from "reactstrap";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { NavLink, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  setFilterCategory,
  clearFilterCategory,
  clearFilterText,
  applyFilter
} from "../../actions/filterActions";
import EventListTableRow from "./EventListTableRow";
import FilterCategoryBadge from "./FilterCategoryBadge";

class EventListTable extends Component {
  static propTypes = {
    filter: PropTypes.object.isRequired,
    setFilterCategory: PropTypes.func.isRequired,
    applyFilter: PropTypes.func.isRequired,
    clearFilterCategory: PropTypes.func.isRequired,
    clearFilterText: PropTypes.func.isRequired
  };

  setFilterCategory = filterCategory => {
    this.props.setFilterCategory(filterCategory);
    this.props.applyFilter();
  };

  clearFilterCategory = () => {
    this.props.clearFilterCategory();
    this.props.applyFilter();
  };

  clearFilterText = () => {
    this.props.clearFilterText();
    this.props.applyFilter();
  };

  render() {
    console.log("EventListTable render");
    const filterText = this.props.filter.filterText;
    const filterCategory = this.props.filter.filterCategory;
    console.log("filterStatusComponent", { filterText, filterCategory });
    let filterStatusComponent = <Fragment></Fragment>;

    if (filterText && filterCategory) {
      filterStatusComponent = (
        <Fragment>
          Tekst:{" "}
          <FilterCategoryBadge
            color="secondary"
            label={filterText}
            hasClosingX="true"
          ></FilterCategoryBadge>
          , Kategoria:{" "}
          <FilterCategoryBadge
            filterCategory={filterCategory}
            onClickHandler={() => this.clearFilterCategory()}
            hasClosingX="true"
          ></FilterCategoryBadge>
        </Fragment>
      );
    }
    if (filterText && !filterCategory) {
      filterStatusComponent = (
        <Fragment>
          Tekst:{" "}
          <FilterCategoryBadge
            color="secondary"
            label={filterText}
            onClickHandler={() => this.clearFilterText()}
            hasClosingX="true"
          ></FilterCategoryBadge>
        </Fragment>
      );
    }
    if (filterCategory && !filterText) {
      filterStatusComponent = (
        <Fragment>
          Kategoria:{" "}
          <FilterCategoryBadge
            filterCategory={filterCategory}
            onClickHandler={() => this.clearFilterCategory()}
            hasClosingX="true"
          ></FilterCategoryBadge>
        </Fragment>
      );
    }
    return (
      <div style={{ position: "relative" }}>
        <div>{filterStatusComponent}</div>
        <table className="table table-condensed table-rounded event-list">
          <tbody>
            {this.props.event.events.map(event => (
              <EventListTableRow
                event={event}
                setFilterCategory={this.setFilterCategory}
                key={event._id}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  filter: state.filter,
  event: state.event
});

export default withRouter(
  connect(mapStateToProps, {
    setFilterCategory,
    clearFilterCategory,
    applyFilter,
    clearFilterText
  })(EventListTable)
);
