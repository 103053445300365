import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import {
  Navbar,
  NavbarBrand,
  Nav,
  Input,
  InputGroup,
  InputGroupAddon,
  Button,
  NavbarToggler,
  NavItem,
  NavLink,
  Collapse,
} from "reactstrap";
import MediaQuery from "react-responsive";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import {
  setFilterText,
  applyFilter,
  clearFilterAll,
} from "./../actions/filterActions";
import PropTypes from "prop-types";
import RegisterModal from "./auth/RegisterModal";
import LoginModal from "./auth/LoginModal";
import Logout from "./auth/Logout";

class AppNavbar extends Component {
  constructor(props) {
    super(props);
    this.searchInputRef = React.createRef();
  }

  state = {
    isOpen: false,
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
    setFilterText: PropTypes.func.isRequired,
    applyFilter: PropTypes.func.isRequired,
    clearFilterAll: PropTypes.func.isRequired,
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  searchInputSubmit = (event) => {
    event.preventDefault();
    this.props.applyFilter();
  };

  searchInputChange = (event) => {
    const filterText = event.target.value;
    this.props.setFilterText(filterText);
  };

  searchInputKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.props.applyFilter();
    }
  };
  searchInputClear = (event) => {
    event.preventDefault();
    console.log(this.searchInputRef, this.searchInputRef.current);
    this.props.clearFilterAll();
    ReactDOM.findDOMNode(this.searchInputRef.current).value = "";
  };

  render() {
    const { isAuthenticated, user } = this.props.auth;

    const authLinks = (
      <Fragment style={{ textAlign: "right" }}>
        <NavItem>
          <Logout />
        </NavItem>
      </Fragment>
    );

    const guestLinks = (
      <Fragment style={{ textAlign: "right" }}>
        <NavItem>
          <RegisterModal />
        </NavItem>
        <NavItem>
          <LoginModal />
        </NavItem>
      </Fragment>
    );

    return (
      <Fragment>
        <MediaQuery maxDeviceWidth={1224}>
          <Navbar
            color="light"
            light
            expand="md"
            className="d-flex justify-content-between"
          >
            <NavbarBrand className="pull-left" href="/">
              SI
            </NavbarBrand>
            <InputGroup
              size="sm"
              className="pull-left"
              style={{ width: "auto" }}
            >
              <Input
                placeholder="Wyszukaj..."
                id="navbarSearchInput"
                ref={this.searchInputRef}
                onChange={this.searchInputChange}
                onKeyDown={this.searchInputKeyDown}
              />
              <InputGroupAddon addonType="append">
                <Button outline size="sm" onClick={this.searchInputClear}>
                  <FontAwesomeIcon icon={faTimes} />
                </Button>
              </InputGroupAddon>
              <InputGroupAddon addonType="append">
                <Button outline size="sm" onClick={this.searchInputSubmit}>
                  <FontAwesomeIcon icon={faSearch} />
                </Button>
              </InputGroupAddon>
            </InputGroup>

            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto text-right" navbar>
                {isAuthenticated ? authLinks : guestLinks}
              </Nav>
            </Collapse>
          </Navbar>
        </MediaQuery>
        <MediaQuery minDeviceWidth={1225}>
          <Navbar
            color="light"
            light
            expand="md"
            className="d-flex justify-content-between"
          >
            <NavbarBrand href="/">Sonar Inbox</NavbarBrand>
            <InputGroup size="lg">
              <Input
                placeholder="Wyszukaj..."
                id="navbarSearchInput"
                ref={this.searchInputRef}
                onChange={this.searchInputChange}
                onKeyDown={this.searchInputKeyDown}
              />
              <InputGroupAddon addonType="append">
                <Button outline size="lg" onClick={this.searchInputClear}>
                  <FontAwesomeIcon icon={faTimes} />
                </Button>
              </InputGroupAddon>
              <InputGroupAddon addonType="append">
                <Button outline size="lg" onClick={this.searchInputSubmit}>
                  <FontAwesomeIcon icon={faSearch} />
                </Button>
              </InputGroupAddon>
            </InputGroup>

            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto text-right" navbar>
                {isAuthenticated ? authLinks : guestLinks}
              </Nav>
            </Collapse>
          </Navbar>
        </MediaQuery>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  setFilterText,
  applyFilter,
  clearFilterAll,
})(AppNavbar);
