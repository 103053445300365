import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";

import FilterCategoryBadge from "../FilterCategoryBadge";

class EventTitleCell extends Component {
  render() {
    let event = this.props.event;
    return (
      <NavLink to={{ pathname: `/event/${event._id}`, event }}>
        <span className={"event-title " + (event.is_read ? "" : "unread")}>
          {event.title}
        </span>

        <span className="d-none">
          <FilterCategoryBadge
            filterCategory={event.event_category}
            onClickHandler={() =>
              this.props.setFilterCategory(event.event_category)
            }
          ></FilterCategoryBadge>
        </span>
      </NavLink>
    );
  }
}

export default withRouter(EventTitleCell);
