import axios from "axios";
import {
  GET_EVENTS,
  EVENTS_LOADING,
  EVENT_UPDATED,
  EVENT_UPDATING,
  EVENT_SET_CURRENT
} from "./types";
import { tokenConfig } from "./authActions";
import { returnErrors } from "./errorActions";

export const getEvents = () => (dispatch, getState) => {
  dispatch(setEventsLoading());
  const filterText = getState().filter.filterText;
  const filterCategory = getState().filter.filterCategory;
  console.log("getState in getEvents", getState());

  let apiEventsURL = "/api/events";
  if (filterCategory) {
    apiEventsURL += "/category/" + filterCategory;
  }
  if (filterText) {
    apiEventsURL += "/text/" + filterText;
  }
  if (!filterCategory && !filterText) {
    apiEventsURL += "/1";
  }
  console.log("apiEventsURL", apiEventsURL);
  axios
    .get(apiEventsURL, tokenConfig(getState))
    .then(res => {
      console.log("Events fetch completed");
      dispatch({
        type: GET_EVENTS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};

export const updateEventIsRead = (eventId, isRead) => (dispatch, getState) => {
  dispatch({
    type: EVENT_UPDATING
  });
  axios
    .put(`/api/events/${eventId}`, { is_read: isRead }, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: EVENT_UPDATED
      });
      const updatedEvent = res.data.event;
      let events = getState().event.events.map((event, index) => {
        if (updatedEvent._id === event._id) {
          return {
            ...event,
            is_read: updatedEvent.is_read
          };
        }
        return event;
      });
      dispatch({
        type: GET_EVENTS,
        payload: events
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};

export const setCurrentEvent = event => dispatch => {
  dispatch({
    type: EVENT_SET_CURRENT,
    payload: event
  });
};

export const setEventsLoading = () => {
  return {
    type: EVENTS_LOADING
  };
};
